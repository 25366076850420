import { CircularProgress, TextField, TextFieldProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { forwardRef, useEffect, useRef } from "react";
import { mergeRefs } from "../../utils/react";

const useStyles = makeStyles((theme) => ({
  rtautocomplete: {
    color: "red",
  },
  itemStyle: {
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "& .rta__entity": {
      background: "transparent",
      padding: theme.spacing(1, 1.5),
    },
    "& .rta__entity--selected": {
      backgroundColor: theme.palette.grey[100],
      color: theme.colors.black,
    },
  },
  listStyle: {
    borderRadius: 8,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MappedInput = forwardRef((props: any, autocompleteRef) => {
  const { passedInputRef, ...rest } = props;
  return <TextField autoComplete="off" inputRef={mergeRefs(autocompleteRef, passedInputRef)} {...rest} />;
});

export function AutocompleteTextfield({
  loadingComponent,
  trigger,
  onChange,
  inputRef,
  containerBounds,
  ...inputProps
}: TextFieldProps & {
  loadingComponent?: JSX.Element;
  containerBounds?: HTMLElement | string;
  trigger: {
    [trigger: string]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataProvider: (token: string) => Array<any>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output: (dataProviderItem: any) => string;
      afterWhitespace: boolean;
    };
  };
}) {
  const classes = useStyles();
  const rta = useRef<ReactTextareaAutocomplete>(null);

  // Set the cursor position to the end position on mount
  useEffect(() => {
    if (inputProps.value && inputProps.autoFocus) {
      rta.current.setCaretPosition((inputProps.value as string).length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactTextareaAutocomplete
      {...inputProps}
      ref={rta}
      className={classes.rtautocomplete}
      passedInputRef={inputRef}
      loadingComponent={loadingComponent || CircularProgress}
      boundariesElement={containerBounds}
      itemClassName={classes.itemStyle}
      listClassName={classes.listStyle}
      dropdownStyle={{
        zIndex: 20,
        overflow: "hidden",
      }}
      textAreaComponent={MappedInput}
      movePopupAsYouType={true}
      minChar={1}
      trigger={trigger}
      onChange={onChange}
    />
  );
}
