import { Collapse, IconButton, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import clsx from "clsx";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { FC, MouseEventHandler, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { useAppContext } from "../context/AppContext";
import { atomSessionStorage } from "../utils/consts";
import { MainSidebarItem, MainSidebarItemProps, MenuSidebarItemIndentContext } from "./MainSidebarItem";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    action: {
      color: "inherit",
    },
    actionClosed: {},
    collapse: {},
  }),
  {
    classNamePrefix: "MainSidebarCollapsableItem",
  }
);

export type MainSidebarCollapsableItemJSSClassKey = keyof ReturnType<typeof useStyles>;

export type MainSidebarCollapsableItemProps = Omit<MainSidebarItemProps, "classes"> & {
  classes?: Partial<ClassNameMap<MainSidebarCollapsableItemJSSClassKey>>;
  className?: string;
  MainSidebarItemClasses?: MainSidebarItemProps["classes"];
  label: ReactNode;
  storageKey: string;
  mountOpen?: boolean;
};

export const MainSidebarCollapsableItem: FC<MainSidebarCollapsableItemProps> = ({
  className,
  classes: extClasses,
  MainSidebarItemClasses,
  children,
  label,
  storageKey,
  mountOpen,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const [open, setOpen] = useAtom(
    useMemo(
      () => atomWithStorage<boolean>(`MainSidebarCollapsableItem.open.${storageKey}`, !!mountOpen, atomSessionStorage),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );

  const {
    state: {
      nav: { open: sidebarOpen },
    },
  } = useAppContext();

  useEffect(() => {
    if (mountOpen) void setOpen(true);
  }, [mountOpen, setOpen]);

  const toggleOpen = useCallback(() => {
    void setOpen(!open);
  }, [open, setOpen]);

  const toggleAndBlock = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      toggleOpen();
    },
    [toggleOpen]
  );

  const indentLevel = useContext(MenuSidebarItemIndentContext);

  return (
    <>
      <MainSidebarItem
        className={clsx(classes.root, className)}
        classes={MainSidebarItemClasses}
        onClick={toggleOpen}
        action={
          <IconButton className={clsx(classes.action, { [classes.actionClosed]: !open })} onClick={toggleAndBlock}>
            {!open ? (
              <ChevronRightRoundedIcon fontSize="small" color="inherit" />
            ) : (
              <ExpandMoreRoundedIcon fontSize="small" color="inherit" />
            )}
          </IconButton>
        }
        {...rest}
      >
        {label}
      </MainSidebarItem>
      <Collapse in={open && sidebarOpen} className={classes.collapse}>
        <MenuSidebarItemIndentContext.Provider value={indentLevel + 1}>
          {children}
        </MenuSidebarItemIndentContext.Provider>
      </Collapse>
    </>
  );
};
